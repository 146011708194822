<template>
  <div class="relative mx-auto">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-truck"></i>
      6th Step: Orders Management
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select as many transporation methods as your company can provide)
    </span>

    <!-- Switches for equipment options -->
    <SwitchesCheckboxes
      class="relative mx-auto"
      :options="ordersManagementOptions"
      :model-value="ordersManagement"
      @update:modelValue="updateOrdersManagementData"
    />
    <div class="button-container relative mx-auto w-fit">
      <button
        :disabled="!canProceed"
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-darkblue text-yellow font-medium border border-darkblue hover:bg-yellow hover:text-darkblue disabled:bg-lightgrey disabled:text-darkblue disabled:cursor-not-allowed disabled:hover:bg-lightgrey disabled:hover:text-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "OrdersManagementStep",
  components: { SwitchesCheckboxes },
  props: {
    ordersManagement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canProceed() {
      // Check if at least one checkbox is selected
      return Object.values(this.ordersManagement).some(
        (value) => value === true,
      );
    },
  },
  data() {
    return {
      ordersManagementOptions: [
        {
          name: "picking",
          label: "Picking",
          value: false,
        },
        {
          name: "packing",
          label: "Packing",
          value: false,
        },
        {
          name: "labeling",
          label: "Labeling",
          value: false,
        },
      ],
    };
  },
  methods: {
    updateOrdersManagementData(updatedValue) {
      this.$emit("update:ordersManagement", updatedValue); // Sync data with parent
    },
    nextStep() {
      this.$emit("change-step", "transportation"); // Emit step change
    },
  },
};
</script>

<style scoped></style>
