<template>
  <div class="relative mx-auto licenses-step">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-box-circle-check"></i>
      7th step: Goods
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select which types of goods can you store and/or are willing to)
    </span>

    <!-- Switches/Checkboxes component -->
    <SwitchesCheckboxes
      :options="goodsOptions"
      :model-value="localGoodsData"
      @update:modelValue="updateGoodsData"
    />
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "GoodsStep",
  components: { SwitchesCheckboxes },
  props: {
    goodsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localGoodsData: { ...this.goodsData },
      goodsOptions: [
        { name: "bulk", label: "Bulk", value: false },
        { name: "dry", label: "Dry", value: false },
        { name: "perishable", label: "Perishable", value: false },
        { name: "chemical", label: "Chemical", value: false },
        { name: "raw", label: "Raw", value: false },
        { name: "construction", label: "Construction", value: false },
        { name: "machinery", label: "Machinery", value: false },
      ],
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    goodsData: {
      deep: true,
      handler(newVal) {
        this.localGoodsData = { ...newVal };
      },
    },
  },
  methods: {
    updateGoodsData(updatedData) {
      this.localGoodsData = updatedData;
      // Emit the update to parent component
      this.$emit("update:goodsData", updatedData);
    },
  },
};
</script>
