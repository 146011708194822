<template>
  <div class="m-auto relative">
    <ol class="switches">
      <li v-for="(option, index) in options" :key="index">
        <input
          type="checkbox"
          :id="`${option.name}-${index}`"
          :value="option.value"
          :checked="modelValue[option.name]"
          @input="handleInput(option.name, $event.target.checked)"
        />
        <label :for="`${option.name}-${index}`">
          <span class="text-sm">{{ option.label }}</span>
          <span></span>
        </label>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "SwitchesCheckboxes",
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleInput(name, checked) {
      this.$emit("update:modelValue", { ...this.modelValue, [name]: checked });
    },
  },
};
</script>
<style scoped>
ol {
  list-style: none;
}

ol label {
  cursor: pointer;
}

[type="checkbox"] {
  position: absolute;
  left: -9999px;
}
.switches {
  max-width: 500px;
  width: 95%;
  margin: 0.75rem auto 0;
  border-radius: 5px;
  color: #004ad0;
}

.switches li {
  position: relative;
  counter-increment: switchCounter;
}

.switches li:not(:last-child) {
  border-bottom: 1px solid #434257;
  border-style: dashed;
}

.switches li::before {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  font-size: 1rem;
  font-weight: bold;
  color: #ff4b77;
}

.switches label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.switches span:last-child {
  position: relative;
  width: 42px;
  height: 22px;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: #e60000;
  transition: all 0.3s;
}

.switches span:last-child::before,
.switches span:last-child::after {
  content: "";
  position: absolute;
}

.switches span:last-child::before {
  left: 1px;
  top: 1px;
  width: 20px;
  height: 20px;
  background: #e8e9ed;
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.3s;
}

.switches span:last-child::after {
  top: 50%;
  right: 8px;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  background: url(../../../assets/images/uncheck-switcher.svg);
  background-size: 10px 10px;
}

.switches [type="checkbox"]:checked + label span:last-child {
  background: #00d084;
}

.switches [type="checkbox"]:checked + label span:last-child::before {
  transform: translateX(20px);
}

.switches [type="checkbox"]:checked + label span:last-child::after {
  width: 10px;
  height: 10px;
  right: auto;
  left: 8px;
  background-image: url(../../../assets/images/checkmark-switcher.svg);
  background-size: 10px 10px;
}
</style>
