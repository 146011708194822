<template>
  <div class="relative mx-auto">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-circle-info"></i>
      10th Step: Other Logistics Details
    </span>
    <span
      v-if="currentStep === 1"
      class="block mt-2 text-base font-normal text-darkblue text-center"
    >
      (Select the logistics details your company works with)
    </span>
    <span
      v-if="currentStep === 2"
      class="block mt-2 text-base font-normal text-darkblue text-center"
    >
      (Select the technological solutions your company works with)
    </span>

    <!-- Step 1: Other Logistics Options -->
    <div v-if="currentStep === 1" class="relative mx-auto">
      <SwitchesCheckboxes
        class="relative mx-auto mb-4"
        :options="otherLogisticsDetailsOptions"
        :model-value="otherLogisticsDetails"
        @update:modelValue="updateLogisticsData"
      />
      <div class="button-container relative mx-auto w-fit">
        <button
          :disabled="!isLogisticsValid"
          class="next-input relative mx-auto rounded-lg mt-5 px-4 py-3 bg-darkblue text-yellow font-medium border border-darkblue hover:bg-yellow hover:text-darkblue disabled:bg-lightgrey disabled:text-darkblue disabled:cursor-not-allowed disabled:hover:bg-lightgrey disabled:hover:text-darkblue"
          @click="goToNextStep"
        >
          Next Section
        </button>
      </div>
    </div>

    <!-- Step 2: Technological Solutions -->
    <div v-if="currentStep === 2" class="relative mx-auto">
      <SwitchesCheckboxes
        class="relative mx-auto"
        :options="technologicalSolutionsOptions"
        :model-value="otherLogisticsDetails.technologicalSolutions"
        @update:modelValue="updateTechnologicalSolutions"
      />
      <div class="button-container relative mx-auto w-fit">
        <button
          :disabled="!canProceed"
          class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-darkblue text-yellow font-medium border border-darkblue hover:bg-yellow hover:text-darkblue disabled:bg-lightgrey disabled:text-darkblue disabled:cursor-not-allowed disabled:hover:bg-lightgrey disabled:hover:text-darkblue"
          @click="nextStep"
        >
          Next Step
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "OtherLogisticsDetailsStep",
  components: { SwitchesCheckboxes },
  props: {
    otherLogisticsDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1, // Controls which section is visible
      otherLogisticsDetailsOptions: [
        { name: "reverseLogistics", label: "Reverse Logistics", value: false },
        { name: "crossDocking", label: "Cross Docking", value: false },
        {
          name: "customsManagement",
          label: "Customs Management",
          value: false,
        },
        {
          name: "logisticsConsulting",
          label: "Logistics Consulting",
          value: false,
        },
      ],
      technologicalSolutionsOptions: [
        { name: "wms", label: "WMS", value: false },
        { name: "tms", label: "TMS", value: false },
        { name: "irtTracking", label: "IRT Tracing", value: false },
      ],
    };
  },
  computed: {
    isLogisticsValid() {
      // Check if at least one checkbox in otherLogisticsDetailsOptions is selected
      return Object.values(this.otherLogisticsDetails).some(
        (value) => value === true,
      );
    },
    canProceed() {
      // Check if at least one checkbox in technologicalSolutionsOptions is selected
      return Object.values(
        this.otherLogisticsDetails.technologicalSolutions || {},
      ).some((value) => value === true);
    },
  },
  methods: {
    updateLogisticsData(updatedValue) {
      this.$emit("update:otherLogisticsDetails", updatedValue); // Sync data with parent
    },
    updateTechnologicalSolutions(updatedValue) {
      const updatedLogistics = {
        ...this.otherLogisticsDetails,
        technologicalSolutions: updatedValue,
      };
      this.$emit("update:otherLogisticsDetails", updatedLogistics); // Sync nested data with parent
    },
    goToNextStep() {
      this.currentStep = 2; // Move to the next section
    },
    nextStep() {
      this.$emit("change-step", "multimedia"); // Emit step change
    },
  },
};
</script>

<style scoped></style>
