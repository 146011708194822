<template>
  <CommonHeader />
  <div class="register-view">
    <RegisterUser />
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import RegisterUser from "@/components/SignIn/RegisterUser.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  metaInfo: {
    title: "User Registry | Biglo",
  },
  name: "UserRegister",
  components: {
    CommonHeader,
    RegisterUser,
    CommonFooter,
  },
};
</script>

<style scoped>
.register-view {
  padding: 10px 0;
  background-color: #051033;
}
</style>
