<template>
  <div class="bg-darkblue text-white p-5 mx-auto rounded-lg shadow-md max-w-lg">
    <h1
      class="text-xl font-semibold text-center mb-8 flex items-center justify-center space-x-2"
    >
      <i class="fa-solid fa-user fa-lg"></i>
      <span>User Registry</span>
    </h1>
    <form id="user-form" @submit.prevent="handleSubmit">
      <!-- Company Name -->
      <label for="name" class="block font-medium mb-1 mt-4">Company Name</label>
      <input
        ref="name"
        class="w-full py-3 px-4 border rounded-lg"
        type="text"
        id="name"
        v-model="form.name"
        required
        autocomplete="on"
        autocorrect="off"
        placeholder="Enter company name"
        minlength="2"
        maxlength="40"
      />

      <!-- Email -->
      <label for="email" class="block font-medium mb-1 mt-4">E-mail</label>
      <input
        class="w-full py-3 px-4 border rounded-lg"
        type="email"
        id="email"
        autocomplete="on"
        autocorrect="off"
        v-model="form.email"
        placeholder="Enter email"
        required
        pattern="^[^\s@+]+@[^\s@]+\.[^\s@]{2,}$"
        title="Please match the requested format. No '+' allowed."
      />

      <!-- Telephone -->
      <label for="telephone" class="block font-medium mb-1 mt-4"
        >Telephone</label
      >
      <div class="flex">
        <input
          class="w-1/4 py-3 px-4 border rounded-l-lg"
          type="text"
          id="telephoneCountryPrefix"
          v-model="form.telephoneCountryPrefix"
          readonly
          placeholder="Prefix"
        />
        <input
          class="w-3/4 py-3 px-4 border rounded-r-lg"
          type="tel"
          id="telephone"
          v-model="form.telephone"
          required
          pattern="^\d{7,15}$"
          placeholder="Enter telephone number"
          @input="restrictToDigits"
        />
      </div>

      <!-- Website -->
      <label for="website" class="block font-medium mb-1 mt-4"
        >Website Address</label
      >
      <input
        ref="website"
        type="url"
        id="website"
        autocomplete="on"
        autocorrect="off"
        v-model="form.website"
        required
        placeholder="Company's website"
        class="w-full py-3 px-4 border rounded-lg"
      />

      <!-- Country -->
      <div class="relative mt-4">
        <p class="block font-medium mb-1">Country</p>
        <CountryInput
          id="country-input"
          v-model="form.country"
          @country-selected="form.country = $event"
          required
        />
      </div>

      <!-- VAT/Tax ID -->
      <label for="vat" class="block font-medium mb-1 mt-4">VAT/Tax ID</label>
      <input
        ref="vat"
        type="text"
        id="vat"
        v-model="form.vat"
        required
        autocomplete="on"
        autocorrect="off"
        placeholder="Company's VAT number (or equivalent)"
        class="w-full py-3 px-4 border rounded-lg"
        minlength="5"
        maxlength="21"
      />

      <!-- Logo -->
      <p class="block font-medium mb-1 mt-4">
        Logo
        <span class="font-normal text-sm"
          >(Not required, you can upload it later)</span
        >
      </p>
      <DropZone @file-uploaded="handleFileUploaded" class="mb-5" />

      <!-- Password -->
      <div>
        <label for="password" class="block font-medium mb-1">Password</label>
        <div class="flex">
          <input
            @focus="handlePasswordFocus()"
            @blur="handlePasswordBlur()"
            ref="password"
            :type="passwordFieldType"
            autocomplete="off"
            autocorrect="off"
            class="w-full py-3 px-4 border rounded-l-lg"
            name="password"
            id="password"
            placeholder="Enter complex password"
            v-model="form.password"
            minlength="10"
            required
            pattern="(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])[A-Za-z\d\W_]+"
            title="At least 10 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special symbol are required."
          />
          <button
            type="button"
            class="min-w-[50px] py-3 px-4 bg-darkblue text-yellow rounded-r-lg border border-l-0 border-yellow br-0 hover:bg-yellow hover:text-darkblue"
            @click="togglePassword"
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
        <span
          v-if="!isPasswordComplex() && form.password.length > 0"
          class="text-center block mt-3 px-3"
          :class="`${passwordErrorMessageColor}`"
        >
          {{ passwordErrorMessage }}
        </span>
      </div>

      <!-- Submit Button -->
      <button
        type="submit"
        class="w-full mt-10 py-3 px-4 bg-darkblue border border-yellow text-yellow rounded-lg font-medium text-lg hover:bg-yellow hover:text-darkblue"
      >
        Register User
      </button>
    </form>

    <!-- Notifications -->
    <NotificationCustom
      v-for="(notification, index) in notifications"
      :key="index"
      :type="notification.type"
      :message="notification.message"
      :duration="notification.duration"
      @hide="removeNotification(index)"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import intlTelInput from "intl-tel-input";
import CountryInput from "@/components/common/Forms/CountryInput.vue";
import NotificationCustom from "@/components/common/NotificationCustom.vue";
import DropZone from "@/components/common/Dropzone.vue";

export default {
  name: "RegisterUser",
  components: {
    CountryInput,
    DropZone,
    NotificationCustom,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        telephoneCountryPrefix: "", // Separate field for country prefix
        telephone: "", // Separate field for telephone number
        website: "",
        country: "",
        vat: "",
        logo: null,
        password: "",
      },
      passwordErrorMessage: "",
      passwordErrorMessageColor: "",
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      selectedFile: null,
      notifications: [],
      iti: null, // Reference to the intl-tel-input instance
    };
  },
  methods: {
    ...mapActions(["register"]),
    async handleSubmit() {
      try {
        // Call Vuex action for registration
        await this.register(this.form);

        // Notify user of success and redirect
        this.addNotification("success", "Registration successful!");
        this.$router.push({ name: "log-in" });
      } catch (error) {
        // Display error notification
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred during registration.";
        this.addNotification("error", errorMessage);
      }
    },
    restrictToDigits(event) {
      const input = event.target.value;
      this.form.telephone = input.replace(/\D/g, ""); // Restrict to digits
    },
    initializePhoneInput() {
      this.$nextTick(() => {
        if (this.iti) {
          this.iti.destroy(); // Destroy any existing instance
        }

        const iti = intlTelInput(this.$refs.telephone, {
          initialCountry: "auto",
          geoIpLookup: (callback) => {
            fetch("https://ipinfo.io/json")
              .then((resp) => resp.json())
              .then((resp) => {
                const countryCode = resp && resp.country ? resp.country : "us";
                callback(countryCode);
              })
              .catch(() => callback("us"));
          },
          autoHideDialCode: false,
          nationalMode: false,
          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });

        iti.promise.then(() => {
          // Set the country prefix based on selected country
          this.form.telephoneCountryPrefix =
            iti.getSelectedCountryData().dialCode || "1";

          this.$refs.telephone.addEventListener("countrychange", () => {
            this.form.telephoneCountryPrefix =
              iti.getSelectedCountryData().dialCode || "1";
          });
        });

        this.iti = iti; // Save reference to destroy later if needed
      });
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
    isPasswordComplex() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])[A-Za-z\d\W_]+$/;
      if (!passwordRegex.test(this.form.password)) {
        this.passwordErrorMessage =
          "At least 10 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special symbol are required.";
        return false;
      } else {
        this.passwordErrorMessage = "";
        return true;
      }
    },
    handlePasswordFocus() {
      this.passwordErrorMessageColor = "text-yellowWarning";
    },
    handlePasswordBlur() {
      this.passwordErrorMessageColor = "text-redError";
    },

    handleFileUploaded(fileId) {
      this.form.logo = fileId;
      console.log("File uploaded successfully, ID:", fileId);
    },

    addNotification(type, message, duration = 10000) {
      this.notifications.push({ type, message, duration });
    },
    removeNotification(index) {
      this.notifications.splice(index, 1);
    },
  },
  mounted() {
    this.initializePhoneInput();
  },
  beforeUnmount() {
    if (this.iti) {
      this.iti.destroy(); // Clean up intl-tel-input instance
    }
  },
};
</script>

<style scoped>
input {
  background-color: #051033;
  border-color: white;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow:
    0 4px 6px -1px rgb(0 128 255 / 0.25),
    0 2px 4px -2px rgb(0 128 255 / 0.25) !important;
}
input:focus:invalid {
  border-color: #f7b500;
  box-shadow:
    0 4px 6px -1px rgb(247 181 0 / 0.25),
    0 2px 4px -2px rgb(247 181 0 / 0.25);
}
input:not(:focus):user-invalid {
  border-color: #e60000;
}
input:user-valid {
  border-color: #00d112 !important;
}
input:focus:user-valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
input:valid {
  border-color: #00d112 !important;
}
input:focus:valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
</style>
