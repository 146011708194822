<template>
  <CommonHeader />
  <div class="password-reset">
    <PasswordReset />
  </div>
</template>
<script>
import CommonHeader from "@/components/common/Header.vue";
import PasswordReset from "@/components/SignIn/PasswordReset.vue";

export default {
  name: "UserVerificationView",
  components: {
    CommonHeader,
    PasswordReset,
  },
};
</script>
<style></style>
