<template>
  <div class="relative block">
    <!-- Filter Header -->
    <h2
      class="text-darkblue font-bold text-2xl border-b-2 border-darkblue pb-2 mb-2"
    >
      Filters
    </h2>

    <!-- Location -->
    <div class="py-4 border-b border-darkblue relative block">
      <h3 class="text-darkblue font-semibold text-lg mb-2">Location</h3>

      <!-- International Coverage Checkbox -->
      <div class="block relative cursor-pointer mt-0.5">
        <label for="international-coverage" class="text-sm"
          >International Coverage</label
        >
        <input
          type="checkbox"
          id="international-coverage"
          v-model="filters.location.internationalCoverage"
          @change="updateLocationType"
        />
      </div>

      <!-- Dynamic Location Type Selector -->
      <select
        id="location-select"
        v-model="filters.location.type"
        class="border border-lightgrey rounded-lg p-2 w-full text-sm my-2"
      >
        <option value="country">Country</option>
        <option value="region">Region</option>
        <option value="city">City</option>
        <option
          v-if="filters.location.internationalCoverage"
          value="continents"
        >
          Continents
        </option>
      </select>

      <!-- Location Input -->
      <!-- Country input -->
      <div class="relative block" v-if="filters.location.type === 'country'">
        <input
          class="border border-lightgrey rounded-lg p-2 w-full text-sm country-input"
          ref="countryInput"
          autocapitalize="on"
          autocorrect="off"
          autocomplete="off"
          type="text"
          v-model="countrySearchQuery"
          @input="
            restrictToLetters($event);
            filterCountries;
          "
          @keydown="navigateCountryList"
          placeholder="Enter the country"
          id="country-input"
        />
      </div>
      <ul
        id="country-list"
        v-show="filteredCountries.length > 0"
        v-if="filters.location.type === 'country'"
      >
        <li
          v-for="(country, index) in filteredCountries"
          :key="country"
          :class="{ selected: index === selectedCountryIndex }"
          @click="selectCountry(country)"
        >
          {{ country }}
        </li>
      </ul>

      <!-- xxx input -->
      <div
        v-if="
          filters.location.type !== 'country' &&
          filters.location.type !== 'continents'
        "
      >
        <input
          v-model="filters.location.search"
          type="text"
          placeholder="Enter a region"
          class="border border-lightgrey rounded-lg p-2 w-full text-sm"
          @input="restrictToLetters($event)"
        />
      </div>
      <div v-if="filters.location.type === 'continents'">
        <SwitchesCheckboxesSM
          :options="continentsOptions"
          v-model="filters.location.continents"
        />
      </div>
    </div>

    <!-- Type of PL -->
    <div class="py-4 border-b border-darkblue">
      <h3 class="text-darkblue font-semibold text-lg mb-1">Type of PL</h3>
      <SwitchesCheckboxesSM
        class="mt-0.5"
        :options="plTypesOptions"
        v-model="filters.location.continents"
      />
    </div>

    <!-- Transportation -->
    <div class="py-4 border-b border-darkblue">
      <h3 class="text-darkblue font-semibold text-lg mb-1">Transportation</h3>
      <SwitchesCheckboxesSM
        class="mt-0.5"
        :options="transportationOptions"
        v-model="filters.transportation"
      />
    </div>

    <!-- Storage Options -->
    <div class="py-4 border-b border-darkblue">
      <h3 class="text-darkblue font-semibold text-lg mb-1">Storage Options</h3>
      <SwitchesCheckboxesSM
        class="mt-0.5"
        :options="storageOptions"
        v-model="filters.storage"
      />
    </div>

    <!-- Sector/Industry -->
    <div class="py-4 border-b border-darkblue">
      <h3 class="text-darkblue font-semibold text-lg mb-1">Sector/Industry</h3>
      <SwitchesCheckboxesSM
        class="mt-0.5"
        :options="industryOptions"
        v-model="filters.industries"
      />
    </div>

    <!-- Contract Options -->
    <div class="py-4 border-b border-darkblue">
      <h3 class="text-darkblue font-semibold text-lg mb-1">Contract Options</h3>
      <SwitchesCheckboxesSM
        class="mt-0.5"
        :options="contractOptions"
        v-model="filters.contracts"
      />
    </div>

    <!-- Orders Management Options -->
    <div class="py-4 mb-3">
      <h3 class="text-darkblue font-semibold text-lg mb-1">
        Orders Management Options
      </h3>
      <SwitchesCheckboxesSM
        class="mt-0.5"
        :options="ordersManagementOptions"
        v-model="filters.ordersManamgent"
      />
    </div>

    <!-- Apply Button -->
    <div class="flex justify-center">
      <button
        @click="applyFilters"
        class="bg-darkblue text-yellow border border-darkblue hover:bg-yellow hover:text-darkblue font-semibold py-2 px-4 rounded shadow-lg"
      >
        Apply Filters
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxesSM from "@/components/Search/Services/SwitchesCheboxesSM.vue";
import countries from "@/assets/location/countries/countries.json";

export default {
  name: "ServicesSearchFilters",
  components: {
    SwitchesCheckboxesSM,
  },
  data() {
    return {
      filters: {
        plTypes: {},
        location: {
          search: "",
          type: "country", // Default type
          internationalCoverage: false, // Determines if continents is available
          continents: {},
        },
        transportation: {},
        storage: {},
        industries: {},
        contracts: {},
        ordersManamgent: {},
      },
      continentsOptions: [
        { name: "europe", label: "Europe", value: true },
        { name: "northAmerica", label: "North America", value: true },
        { name: "asia", label: "Asia", value: true },
        { name: "southAmerica", label: "South America", value: true },
        { name: "oceania", label: "Oceania", value: true },
        { name: "africa", label: "Africa", value: true },
      ],
      plTypesOptions: [
        { name: "2PL", label: "2PL", value: true },
        { name: "3PL", label: "3PL", value: true },
        { name: "4PL", label: "4PL", value: true },
        { name: "5PL", label: "5PL", value: true },
      ],
      transportationOptions: [
        { name: "aerialTransportation", label: "Aerial", value: false },
        { name: "groundTransportation", label: "Ground", value: false },
        { name: "maritimeTransportation", label: "Maritime", value: false },
        { name: "lastMile", label: "Last Mile", value: false },
        { name: "multimodalTransportation", label: "Multimodal", value: false },
      ],
      storageOptions: [
        { name: "dry", label: "Dry", value: false },
        { name: "refrigerated", label: "Refrigerated", value: false },
        { name: "freezer", label: "Freezer", value: false },
        { name: "dangerousGoods", label: "Dangerous Goods", value: false },
      ],
      industryOptions: [
        { name: "retail", label: "Retail", value: false },
        { name: "foodsAndDrinks", label: "Foods & Drinks", value: false },
        { name: "pharma", label: "Pharma", value: false },
        { name: "automotive", label: "Automotive", value: false },
        { name: "technology", label: "Technology", value: false },
        { name: "clothes", label: "Clothes", value: false },
      ],
      contractOptions: [
        { name: "shortTermOperations", label: "Short-Term", value: false },
        { name: "longTermOperations", label: "Long-Term", value: false },
        { name: "ocasionalOperations", label: "Occasional", value: false },
      ],
      ordersManagementOptions: [
        { name: "picking", label: "Picking", value: false },
        { name: "packing", label: "Packing", value: false },
        { name: "labeling", label: "Labeling", value: false },
      ],
      countrySearchQuery: this.initialCountry,
      filteredCountries: [],
      selectedCountryIndex: -1,
    };
  },
  computed: {
    country() {
      return this.countrySearchQuery;
    },
  },
  methods: {
    updateLocationType() {
      if (this.filters.location.internationalCoverage) {
        this.filters.location.type = "continents";
      } else if (this.filters.location.type === "continents") {
        this.filters.location.type = "country";
      }
    },
    applyFilters() {
      this.$emit("apply-filters", this.filters);
    },

    restrictToLetters(event) {
      const input = event.target;
      input.value = input.value.replace(/[^a-zA-Z\s]/g, "");
    },

    /* Country methods */
    filterCountries() {
      if (this.countrySearchQuery.length > 0) {
        this.filteredCountries = countries.filter((country) =>
          country
            .toLowerCase()
            .startsWith(this.countrySearchQuery.toLowerCase()),
        );
        this.selectedCountryIndex = -1;
      } else {
        this.filteredCountries = [];
      }
    },
    selectCountry(country) {
      this.countrySearchQuery = country;
      this.filteredCountries = [];
      this.selectedCountryIndex = -1;
      this.$emit("country-selected", country);
    },
    navigateCountryList(event) {
      if (this.filteredCountries.length > 0) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex + 1) % this.filteredCountries.length;
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex - 1 + this.filteredCountries.length) %
            this.filteredCountries.length;
        } else if (event.key === "Enter" && this.selectedCountryIndex >= 0) {
          event.preventDefault();
          this.selectCountry(this.filteredCountries[this.selectedCountryIndex]);
        }
      }
    },
  },
};
</script>

<style scoped>
#international-coverage,
#international-coverage:checked {
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
}
#international-coverage:before {
  content: "";
  display: inline-block;
  width: 16px; /* Size of the checkbox */
  height: 16px; /* Size of the checkbox */
  border: 1px solid #004aad; /* Border color */
  border-radius: 2px; /* Rounded corners */
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Checked state style */
#international-coverage:checked:before {
  content: "✕";
  color: #efb615;
  background-color: #004aad;
  display: inline-block;
  width: 16px; /* Size of the checkbox */
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
  border-radius: 2px; /* Rounded corners */
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
/* Country Input Styles */
#country-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 333px;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;
}

#country-list li {
  padding: 0.67rem 1rem;
  background: white;
}

#country-list li:hover,
#country-list .selected {
  background-color: #004aad;
  color: #efb615;
  cursor: pointer;
}
#country-list li:first-child {
  border-radius: 8px 8px 0 0;
}
#country-list li:last-child {
  border-radius: 0 0 8px 8px;
}
#country-list li:first-child:last-child {
  border-radius: 8px;
}
</style>
