<template>
  <CommonHeader />
  <RegisterServiceForm />
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import ServicesSearch from "@/components/Search/Services/ServicesSearch.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  name: "SpaceRegistry",
  metaInfo: {
    title: "Space Registry | Biglo",
  },
  components: {
    CommonHeader,
    RegisterServiceForm: ServicesSearch,
    CommonFooter,
  },
};
</script>
<style scoped></style>
