<template>
  <!-- Steps Nav Buttons -->
  <nav class="flex md:flex-nowrap mx-auto min-md:maw-w-fit my-5">
    <div
      class="flex mx-auto md:flex-nowrap md:overflow-x-auto md:snap-x md:scroll-px-1 md:ml-1 items-center overflow-y-hidden"
    >
      <NavButton
        v-for="(step, index) in steps"
        :key="step.name"
        :stepName="step.name"
        :iconClass="step.icon"
        :isCurrent="currentStep === step.name"
        :isActive="
          index === 0 ||
          (index <= steps.findIndex((s) => s.name === currentStep) &&
            isStepValid(steps[index - 1]?.name))
        "
        :showSeparator="index > 0"
        :separatorClass="index > 0 ? 'separator-blue' : ''"
        :additionalClasses="
          index === 0
            ? 'snap-start'
            : index === steps.length - 1
              ? 'snap-end'
              : ''
        "
        class="shrink-0 relative"
        @navigate="navigateStep(step.name)"
      />
    </div>
  </nav>

  <!-- Input Nav Buttons & Main -->
  <div class="wrapper mt-2.5">
    <!-- Register Service Form -->
    <form class="register-space-form" @submit.prevent="submitForm">
      <PlTypeStep
        v-if="currentStep === 'plType'"
        v-model:plType="plType"
        @change-step="navigateStep"
      />

      <LocationStep
        v-if="currentStep === 'location'"
        v-model:location="location"
        @change-step="navigateStep"
      />

      <DimensionsStep
        v-if="currentStep === 'dimensions'"
        v-model:dimensions="dimensions"
        @change-step="navigateStep"
      />

      <StorageAndInventoryStep
        v-if="currentStep === 'storageAndInventory'"
        v-model:storageAndInventory="storageAndInventory"
        @change-step="navigateStep"
      />

      <SectorIndustryStep
        v-if="currentStep === 'sectorIndustry'"
        v-model:sectorIndustry="sectorIndustry"
        @change-step="navigateStep"
      />

      <OrdersManagementStep
        v-if="currentStep === 'ordersManagement'"
        v-model:ordersManagement="ordersManagement"
        @change-step="navigateStep"
      />

      <TransportationStep
        v-if="currentStep === 'transportation'"
        v-model:transportation="transportation"
        @change-step="navigateStep"
      />

      <LicensesStep
        v-if="currentStep === 'licenses'"
        v-model:licenses="licenses"
        @change-step="navigateStep"
      />

      <ContractStep
        v-if="currentStep === 'contract'"
        v-model:contract="contract"
        @change-step="navigateStep"
      />

      <OtherLogisticsDetailsStep
        v-if="currentStep === 'otherLogisticsDetails'"
        v-model:otherLogisticsDetails="otherLogisticsDetails"
        @change-step="navigateStep"
      />

      <MultimediaStep
        v-if="currentStep === 'multimedia'"
        v-model:multimedia="multimedia"
        @change-step="navigateStep"
      />

      <DescriptionStep
        v-if="currentStep === 'description'"
        v-model:description="description"
        @change-step="navigateStep"
      />
    </form>
  </div>

  <!-- Custom Notification Component -->
  <NotificationCustom
    v-if="notificationVisible"
    :type="notificationType"
    :message="notificationMessage"
    :duration="5000"
  />
</template>

<script>
import NavButton from "@/components/common/Forms/NavButton.vue";
import PlTypeStep from "@/components/Services/Steps/1PlTypeStep.vue";
import LocationStep from "@/components/Services/Steps/2LocationStep.vue";
import DimensionsStep from "@/components/Services/Steps/3DimensionsStep.vue";
import StorageAndInventoryStep from "@/components/Services/Steps/4StorageAndInventoryStep.vue";
import SectorIndustryStep from "@/components/Services/Steps/5SectorIndustryStep.vue";
import OrdersManagementStep from "@/components/Services/Steps/6OrdersManagementStep.vue";
import TransportationStep from "@/components/Services/Steps/7TransportationStep.vue";
import LicensesStep from "@/components/Services/Steps/8LicensesStep.vue";
import ContractStep from "@/components/Services/Steps/9ContractStep.vue";
import OtherLogisticsDetailsStep from "@/components/Services/Steps/10OtherLogisticsDetailsStep.vue";
import MultimediaStep from "@/components/Services/Steps/11MultimediaStep.vue";
import DescriptionStep from "@/components/Services/Steps/12DescriptionStep.vue";
import NotificationCustom from "@/components/common/NotificationCustom.vue";
import axios from "axios";

export default {
  name: "RegisterSpaceForm",
  components: {
    PlTypeStep,
    NavButton,
    LocationStep,
    DimensionsStep,
    StorageAndInventoryStep,
    LicensesStep,
    MultimediaStep,
    DescriptionStep,
    OrdersManagementStep,
    TransportationStep,
    SectorIndustryStep,
    ContractStep,
    OtherLogisticsDetailsStep,
    NotificationCustom,
  },
  data() {
    return {
      currentStep: "plType",
      steps: [
        { name: "plType", icon: "fa-solid fa-clipboard-question" },
        { name: "location", icon: "fa-solid fa-map-location-dot" },
        { name: "dimensions", icon: "fa-solid fa-ruler" },
        { name: "storageAndInventory", icon: "fa-solid fa-warehouse-full" },
        { name: "sectorIndustry", icon: "fa-solid fa-industry" },
        { name: "ordersManagement", icon: "fa-solid fa-box" },
        { name: "transportation", icon: "fa-solid fa-truck" },
        { name: "licenses", icon: "fa-solid fa-file-certificate" },
        { name: "contract", icon: "fa-solid fa-file-contract" },
        { name: "otherLogisticsDetails", icon: "fa-solid fa-circle-info" },
        { name: "multimedia", icon: "fa-solid fa-photo-film" },
        { name: "description", icon: "fa-solid fa-text" },
      ],
      plType: {
        pl2: false,
        pl3: false,
        pl4: false,
        pl5: false,
      },
      location: {
        country: "",
        region: "",
        city: "",
        zip: "",
        address: "",
        hasOtherLocations: false,
        otherLocations: [],
        hasInternationalCoverage: false,
        internationalCoverage: {
          europe: false,
          asia: false,
          northAmerica: false,
          southAmerica: false,
          africa: false,
        },
      },
      dimensions: {
        surface: 0,
        height: 0,
        palletPositions: 0,
      },
      storageAndInventory: {
        dry: false,
        refrigerated: false,
        freezer: false,
        dangerousGoods: false,
      },
      sectorIndustry: {
        retail: false,
        foodsAndDrinks: false,
        pharma: false,
        automotive: false,
        technology: false,
        clothes: false,
      },
      ordersManagement: {
        picking: false,
        packing: false,
        labeling: false,
      },
      transportation: {
        aerialTransportation: false,
        maritimeTransportation: false,
        groundTransportation: false,
        lastMile: false,
        multimodalTransportation: false,
      },
      licenses: {
        iso9001: false,
        iso14001: false,
        iso45001: false,
        ohsas18001: false,
        haccp: false,
        customs: false,
        chemicalHandling: false,
        fireSafety: false,
        breeam: false,
        leed: false,
      },
      contract: {
        shortTermOperations: false,
        longTermOperations: false,
        occasionalOperations: false,
      },
      otherLogisticsDetails: {
        reverseLogistics: false,
        crossDocking: false,
        customsManagement: false,
        logisticsConsulting: false,
        technologicalSolutions: {
          wms: false,
          tms: false,
          irtTracking: false,
        },
      },
      multimedia: {
        images: [],
        videos: [],
        blueprints: [],
      },
      description: "",
      userId: this.$store.state.user ? this.$store.state.user._id : null,
      userName: this.$store.state.user ? this.$store.state.user.name : null,
      loading: false,
      notificationVisible: false, // Control visibility of the notification
      notificationType: "",
      notificationMessage: "",
    };
  },
  methods: {
    navigateStep(step) {
      if (this.isStepValid(this.currentStep) || step === this.currentStep) {
        this.currentStep = step;
      }
    },
    canProceedToNextStep() {
      return this.isStepValid(this.currentStep);
    },
    isStepValid(stepName) {
      const isAnyTrue = (obj) =>
        Object.values(obj).some((value) => value === true);
      const isNonEmptyString = (obj) =>
        Object.values(obj).every((value) => value.trim() !== "");
      const isValidNumber = (obj) =>
        Object.values(obj).every(
          (value) => typeof value === "number" && value >= 0,
        );

      const validators = {
        plType: () => isAnyTrue(this.plType),
        location: () => {
          const requiredFields = {
            country: this.location.country,
            region: this.location.region,
            city: this.location.city,
            zip: this.location.zip,
            address: this.location.address,
          };
          return Object.values(requiredFields).every(isNonEmptyString);
        },
        dimensions: () => isValidNumber(this.dimensions),
        storageAndInventory: () => isAnyTrue(this.storageAndInventory),
        sectorIndustry: () => isAnyTrue(this.sectorIndustry),
        licenses: () => isAnyTrue(this.licenses),
        ordersManagement: () => isAnyTrue(this.ordersManagement),
        transportation: () => isAnyTrue(this.transportation),
        contract: () => isAnyTrue(this.contract),
        otherLogisticsDetails: () => isAnyTrue(this.otherLogisticsDetails),
        multimedia: () => true,
        description: () => this.description.descriptionText.trim().length >= 50,
      };

      const result = validators[stepName] ? validators[stepName]() : false;
      console.log(`Validation for step "${stepName}":`, result);
      console.log(this.dimensions);
      return result;
    },

    nextStep() {
      const currentIndex = this.steps.findIndex(
        (step) => step.name === this.currentStep,
      );
      if (currentIndex < this.steps.length - 1) {
        this.navigateStep(this.steps[currentIndex + 1].name);
      }
    },
    showNotification(type, message) {
      this.notificationType = type;
      this.notificationMessage = message;
      this.notificationVisible = true;

      setTimeout(() => {
        this.notificationVisible = false;
      }, 10000);
    },
    async submitForm() {
      this.loading = true; // Assuming you have a loading variable
      try {
        const payload = {
          plType: this.plType,
          location: this.location,
          dimensions: this.dimensions,
          storageAndInventory: this.storageAndInventory,
          licenses: this.licenses,
          multimedia: this.multimedia,
          description: this.description,
          ordersManagement: this.ordersManagement,
          transportation: this.transportation,
          sectorIndustry: this.sectorIndustry,
          contract: this.contract,
          otherLogisticsDetails: this.otherLogisticsDetails,
          userId: this.userId,
          userName: this.userName,
        };
        console.log(payload);

        const response = await axios.post("/api/services/register", payload);

        // Check if the response indicates success
        if (response.status === 201 || response.status === 200) {
          console.log("Form submitted successfully:", response.data);
          this.showNotification("success", "Form submitted successfully.");
          this.resetForm();

          this.$router.push({ path: "/your-account" }); // Redirect only on success
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error(
          "Error submitting form:",
          error.response?.data || error.message,
        );
        this.showNotification(
          "error",
          "There was an error submitting the form.",
        );
      } finally {
        this.loading = false; // Deactivate loading state
      }
    },
    resetForm() {
      this.plType = {};
      this.location = {};
      this.dimensions = {};
      this.storageAndInventory = {};
      this.sectorIndustry = {};
      this.ordersManagement = {};
      this.transportation = {};
      this.licenses = {};
      this.contract = {};
      this.otherLogisticsDetails = {};
      this.multimedia = {};
      this.description = {};
      this.currentStep = "plType";
    },
  },
};
</script>
