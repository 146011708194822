<template>
  <CommonHeader />
  <div class="min-md:max-w-[95dvw] mx-auto">
    <RegisterSpaceOrService />
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import RegisterSpaceOrService from "@/components/others/RegisterSpaceOrService.vue";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RegisterSpaceOrServiceView",
  metaInfo: {
    title: "Register Your Space | Biglo",
  },
  components: {
    CommonHeader,
    RegisterSpaceOrService,
    CommonFooter,
  },
});
</script>
<style scoped></style>
