<template>
  <div class="blog">
    <CommonHeader />
    <h1>BLOG</h1>
    <CommonFooter />
  </div>
</template>
<script>
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  name: "BlogView",
  components: { CommonHeader, CommonFooter },
};
</script>
<style scoped></style>
