<template>
  <CommonHeader />
  <div class="log-in">
    <UserLogin />
  </div>
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import UserLogin from "@/components/SignIn/UserLogin.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogInPage",
  metaInfo: {
    title: "Biglo | Sign In",
  },
  components: {
    CommonHeader,
    UserLogin,
  },
});
</script>

<style scoped>
.log-in {
  height: calc(100dvh - 86px);
  padding: 10px 0;
  background-color: #051033;
}
</style>
