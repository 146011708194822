<template>
  <div class="relative mx-auto">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-shelves"></i>
      4th Step: Storage & Inventory
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select as many ... as your company ...)
    </span>

    <!-- Switches/Checkboxes component -->
    <SwitchesCheckboxes
      class="relative mx-auto"
      :options="storageAndInventoryOptions"
      :model-value="storageAndInventory"
      @update:modelValue="updateStorageAndInventoryData"
    />
    <div class="button-container relative mx-auto w-fit">
      <button
        :disabled="!canProceed"
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-darkblue text-yellow font-medium border border-darkblue hover:bg-yellow hover:text-darkblue disabled:bg-lightgrey disabled:text-darkblue disabled:cursor-not-allowed disabled:hover:bg-lightgrey disabled:hover:text-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "StorageAndInventoryStep",
  components: { SwitchesCheckboxes },
  props: {
    storageAndInventory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canProceed() {
      // Check if at least one checkbox is selected
      return Object.values(this.storageAndInventory).some(
        (value) => value === true,
      );
    },
  },
  data() {
    return {
      storageAndInventoryOptions: [
        { name: "dry", label: "Dry", value: false },
        { name: "refrigerated", label: "Refrigerated", value: false },
        { name: "dangerousGoods", label: "Dangerous Goods", value: false },
      ],
    };
  },
  methods: {
    updateStorageAndInventoryData(updatedValue) {
      this.$emit("update:storageAndInventory", updatedValue); // Sync data with parent
    },
    nextStep() {
      this.$emit("change-step", "sectorIndustry"); // Emit step change
    },
  },
};
</script>

<style scoped></style>
