<template>
  <CommonHeader />
  <div class="container100svh-362px">
    <img
      src="@/assets/images/biglo_logo.png"
      class="w-48 mx-auto"
      alt="Biglo's Logo"
    />
    <h1 class="title">
      <span>The page you’re looking for</span>
      <span class="block mx-auto">can’t be found.</span>
    </h1>
    <router-link to="/" class="link-return-home">
      <i class="fa-solid fa-house-chimney mr-2 ml-1"> </i>Want to return home?
    </router-link>
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";
export default {
  name: "NotFoundPage",
  metaInfo: {
    title: "404: Page not found | Biglo",
  },
  components: {
    CommonHeader,
    CommonFooter,
  },
};
</script>

<style scoped>
.container100svh-362px {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100svh - 342px);
}
.title {
  font-size: 1.875rem;
  font-weight: 700;
  color: #004aad;
  max-width: fit-content;
  margin: 24px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.link-return-home {
  margin: 8px auto 32px auto;
  border: 1px solid #004aad;
  color: #efb615;
  font-size: 1.25rem;
  background-color: #004aad;
  padding: 8px 10px;
  font-weight: 500;
  border-radius: 6px;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
  width: fit-content;
}
.link-return-home:hover {
  color: #004aad;
  background-color: #efb615;
}
</style>
