<template>
  <div
    class="relative transition-all duration-200 ease-in-out flex flex-row items-center"
  >
    <!-- Button to toggle dropdown -->
    <button
      id="toggle-dropdown"
      type="button"
      class="py-3 pl-4 pr-1 border rounded-l-lg flex items-center justify-center relative bg-darkblue border border-white hover:border-yellow hover:text-yellow transition-colors duration-200 ease-in-out"
      @click="toggleDropdown"
    >
      <img
        v-if="selectedCountry"
        :src="`https://flagcdn.com/h20/${selectedCountry.code.toLowerCase()}.jpg`"
        :alt="selectedCountry.name"
        class="w-6 h-6 py-1"
      />
      <span v-else>🌍</span>

      <!-- Dropdown arrow -->
      <div
        class="transition-transform duration-200 ease-in-out ml-2"
        :class="{ 'rotate-180': isDropdownOpen }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </button>

    <!-- Dial code -->
    <span
      v-if="selectedCountry"
      class="px-2 py-3 self-center border-y border-white w-[5.23125rem] text-center truncate"
    >
      {{ selectedCountry.dialCode }}
    </span>

    <!-- Dropdown with flags grouped by continent -->
    <div
      v-if="isDropdownOpen"
      class="absolute z-10 w-[9.7rem] bg-white border border-gray-300 rounded-lg shadow-lg max-h-64 overflow-y-auto mt-px"
      style="top: 100%; left: 0"
    >
      <!-- Loop through each continent -->
      <div v-for="(countries, continent) in groupedCountries" :key="continent">
        <!-- Continent header -->
        <div class="px-4 py-2 font-semibold bg-gray-100 text-darkblue">
          {{ continent }}
        </div>
        <!-- Loop through countries in the continent -->
        <div
          v-for="country in countries"
          :key="country.code"
          class="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
          @click="selectCountry(country)"
        >
          <img
            :src="`https://flagcdn.com/${country.code.toLowerCase()}.svg`"
            :alt="country.code"
            loading="lazy"
            class="w-6 mr-2"
          />
          <!-- Display the dial code instead of the country name -->
          <span class="text-darkblue">{{ country.dialCode }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/location/countries/countriesDialCode.json";

export default {
  name: "TelephoneCountryPrefixCustom",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      isDropdownOpen: false,
      selectedCountry: null, // Selected country object
      groupedCountries: countries, // Use the grouped countries directly
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.$emit("update:modelValue", country.dialCode); // Emit the selected dial code
      this.isDropdownOpen = false;
    },
  },
  mounted() {
    // Set default country (e.g., US)
    const allCountries = Object.values(this.groupedCountries).flat();
    this.selectedCountry = allCountries.find((c) => c.code === "US");
    this.$emit("update:modelValue", this.selectedCountry.dialCode); // Emit default dial code
  },
};
</script>

<style scoped>
/* Flag image hover effect */
img,
span {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.rotate-180 {
  transform: rotate(180deg);
}
</style>
