<template>
  <!-- Wrapper for Nav Button and Separator -->
  <div class="flex items-center relative">
    <!-- Separator -->
    <div
      v-if="showSeparator"
      class="relative shrink-0"
      :class="[
        orientation === 'vertical' ? 'vertical' : 'horizontal',
        isActive
          ? 'border border-darkblue bg-darkblue'
          : 'border border-lightgrey bg-lightgrey',
      ]"
    ></div>

    <!-- Navigation Button -->
    <button
      :data-text="isActive ? stepName : 'Please, fill the previous steps'"
      :disabled="!isActive"
      class="relative flex justify-center items-center transition-all duration-150 rounded-full border border-darkblue flex-shrink-0 z-10"
      :class="[
        isActive
          ? 'text-yellow bg-darkblue hover:bg-yellow hover:text-darkblue'
          : 'bg-lightgrey text-darkblue cursor-not-allowed',
        isCurrent ? 'min-w-16 min-h-16' : 'min-w-12 min-h-12',
        'step-nav-tooltip',
        additionalClasses,
      ]"
      @click="$emit('navigate', stepName)"
    >
      <i :class="[iconClass, isCurrent ? 'text-2xl' : 'text-lg']"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "NavButton",
  props: {
    stepName: { type: String, required: true },
    isCurrent: { type: Boolean, required: true },
    isActive: { type: Boolean, required: true },
    iconClass: { type: String, required: true },
    showSeparator: { type: Boolean, default: false },
    separatorClass: { type: String, default: "" },
    orientation: {
      type: String,
      default: "horizontal",
      validator: (value) => ["horizontal", "vertical"].includes(value),
    },
    additionalClasses: { type: String, default: "" },
  },
};
</script>

<style scoped>
/* Tooltip style */
.step-nav-tooltip:after {
  top: 67px;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  opacity: 0;
  background-color: #004aad;
  color: #efb615;
  z-index: 20;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.step-nav-tooltip:hover:after {
  opacity: 1;
}

/* Separator styles */
.horizontal {
  position: relative;
  width: 12.5px;
  height: 4px;
}
.vertical {
  position: relative;
  height: 12.5px;
  width: 4px;
}
@media (max-width: 768px) {
  .horizontal {
    position: relative;
    width: 12.5px;
    height: 4px;
  }
  .vertical {
    position: relative;
    height: 12.5px;
    width: 4px;
  }
  .horizontal .separator-blue {
    border-color: #004aad;
    background-color: #004aad;
    width: 12.5px;
    height: 4px;
  }
}

.horizontal .separator-blue {
  border-color: #004aad;
  background-color: #004aad;
  width: 12.5px;
  height: 4px;
}
</style>
