<template>
  <div class="m-auto relative">
    <ol class="switch">
      <li>
        <input
          type="checkbox"
          :id="id"
          :checked="modelValue"
          @input="handleInput($event.target.checked)"
        />
        <label :for="id">
          <span>{{ label }}</span>
          <span></span>
        </label>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "SwitchCheckbox",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInput(checked) {
      this.$emit("update:modelValue", checked);
    },
  },
};
</script>

<style scoped>
ol {
  list-style: none;
}

ol label {
  cursor: pointer;
}

[type="checkbox"] {
  position: absolute;
  left: -9999px;
}
.switch {
  max-width: 500px;
  width: 95%;
  margin: 16px auto 0;
  border-radius: 5px;
  color: #004ad0;
}

.switch li {
  position: relative;
}

.switch label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.switch span:last-child {
  position: relative;
  width: 50px;
  height: 26px;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: #da0000;
  transition: all 0.3s;
}

.switch span:last-child::before,
.switch span:last-child::after {
  content: "";
  position: absolute;
}

.switch span:last-child::before {
  left: 1px;
  top: 1px;
  width: 24px;
  height: 24px;
  background: #e8e9ed;
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.3s;
}

.switch span:last-child::after {
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  background: url(../../../assets/images/uncheck-switcher.svg);
  background-size: 12px 12px;
}

.switch [type="checkbox"]:checked + label span:last-child {
  background: #00d084;
}

.switch [type="checkbox"]:checked + label span:last-child::before {
  transform: translateX(24px);
}

.switch [type="checkbox"]:checked + label span:last-child::after {
  width: 14px;
  height: 14px;
  right: auto;
  left: 8px;
  background-image: url(../../../assets/images/checkmark-switcher.svg);
  background-size: 14px 14px;
}
</style>
