<template>
  <div @blur="hideCountryList" class="relative">
    <input
      type="text"
      id="country"
      v-model="countrySearchQuery"
      @input="filterCountries"
      @keydown="navigateCountryList"
      @focus="showCountryList"
      required
      autocomplete="off"
      autocorrect="off"
      placeholder="Company's country"
      class="w-full px-4 py-3 border rounded-lg bg-darkestBlue"
      :class="{
        'country-not-selected': isCountryNotSelected,
        'country-selected': isCountrySelected,
      }"
      minlength="4"
    />
    <ul
      v-if="isCountryListVisible && filteredCountries.length > 0"
      id="country-list"
      ref="countryList"
      role="listbox"
      aria-labelledby="country"
    >
      <li
        v-for="(country, index) in filteredCountries"
        :key="country"
        :class="{ selected: index === selectedCountryIndex }"
        @click="selectCountry(country)"
        @mousedown.prevent
        role="option"
        :aria-selected="index === selectedCountryIndex"
        class="p-3"
      >
        {{ country }}
      </li>
    </ul>
  </div>
</template>

<script>
import countries from "@/assets/location/countries/countries.json";

export default {
  name: "CountryInput",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      countrySearchQuery: this.value || "",
      filteredCountries: [],
      selectedCountryIndex: -1,
      isCountryListVisible: false,
    };
  },
  computed: {
    isCountrySelected() {
      return countries.includes(this.countrySearchQuery);
    },
    isCountryNotSelected() {
      return this.countrySearchQuery.length > 0 && !this.isCountrySelected;
    },
  },
  methods: {
    filterCountries() {
      if (this.countrySearchQuery.length > 0) {
        this.filteredCountries = countries.filter((country) =>
          country
            .toLowerCase()
            .startsWith(this.countrySearchQuery.toLowerCase()),
        );
        this.selectedCountryIndex = -1; // Reset index when query changes
        this.isCountryListVisible = true;
      } else {
        this.filteredCountries = [];
        this.isCountryListVisible = false;
      }
    },
    hideCountryList() {
      this.isCountryListVisible = false;
    },
    selectCountry(country) {
      this.countrySearchQuery = country;
      this.filteredCountries = [];
      this.isCountryListVisible = false;
      this.selectedCountryIndex = -1;
      this.$emit("country-selected", country);
    },
    navigateCountryList(event) {
      if (this.filteredCountries.length > 0) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex + 1) % this.filteredCountries.length;
          this.scrollToSelectedCountry();
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex - 1 + this.filteredCountries.length) %
            this.filteredCountries.length;
          this.scrollToSelectedCountry();
        } else if (event.key === "Enter" && this.selectedCountryIndex >= 0) {
          event.preventDefault();
          this.selectCountry(this.filteredCountries[this.selectedCountryIndex]);
        } else if (event.key === "Escape") {
          this.hideCountryList();
          this.selectedCountryIndex = -1;
        }
      }
    },
    showCountryList() {
      this.isCountryListVisible = true;
    },
    scrollToSelectedCountry() {
      this.$nextTick(() => {
        const list = this.$refs.countryList;
        const selectedOption = list.children[this.selectedCountryIndex];
        if (selectedOption) {
          list.scrollTop =
            selectedOption.offsetTop -
            list.clientHeight / 2 +
            selectedOption.clientHeight / 2;
        }
      });
    },
  },
  watch: {
    value(newVal) {
      this.countrySearchQuery = newVal;
    },
  },
};
</script>

<style scoped>
#country-list {
  color: white;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 318px;
  overflow-y: auto;
  background-color: #051033;
  border-top: none;
  border-left: 1px solid #004aad;
  border-bottom: 1px solid #004aad;
  border-right: 1px solid #004aad;
  border-radius: 8px;
  list-style-type: none;
}
ul li {
  padding: 0.67rem 1rem;
  background: #051033;
}

ul li:hover,
ul .selected {
  background-color: #004aad;
  color: #efb615;
  cursor: pointer;
}

ul li:first-child {
  border-radius: 8px 8px 0 0;
}
ul li:last-child {
  border-radius: 0 0 8px 8px;
}
ul li:first-child:last-child {
  border-radius: 8px;
}
input {
  border-color: white;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow:
    0 4px 6px -1px rgb(0 128 255 / 0.25),
    0 2px 4px -2px rgb(0 128 255 / 0.25) !important;
}
.country-not-selected:focus {
  border-color: #f7b500 !important;
  box-shadow:
    0 4px 6px -1px rgb(247 181 0 / 0.25),
    0 2px 4px -2px rgb(247 181 0 / 0.25) !important;
}

.country-not-selected {
  border-color: #e60000;
}
input:user-invalid {
  border-color: #e60000;
}
.country-selected {
  border-color: #00d112 !important;
}
.country-selected:focus {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
</style>
