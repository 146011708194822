<template>
  <div class="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg">
    <h1 class="text-xl font-bold text-darkblue text-center mb-4">
      Reset Password
    </h1>
    <!-- Success Message with Countdown and Redirect Options -->
    <div
      v-if="!loading && success"
      class="status success text-greenSuccess flex flex-col space-y-1"
    >
      <p class="my-2 mx-auto max-w-min">
        <i class="fa fa-check-circle fa-2xl"></i>
      </p>
      <p class="achant">Your password has been reset successfully!</p>
      <div class="redirect text-center">
        <p>Redirecting in {{ countdown }} seconds...</p>
        <p>
          If not redirected, you can go to

          <router-link class="link" v-if="!isAuthenticated" to="/log-in">
            log-in
          </router-link>

          <router-link class="link" v-if="isAuthenticated" to="/your-account">
            your-account
          </router-link>

          or
          <router-link class="link" to="/">return home</router-link>.
        </p>
      </div>
    </div>

    <!-- Error Message -->
    <div
      v-if="!loading && error"
      class="status error text-redError flex flex-col space-y-1"
    >
      <p class="my-2 mx-auto max-w-min">
        <i class="fa fa-exclamation-triangle fa-2xl"></i>
      </p>
      <p class="achant">Password reset failed: {{ serverMessage }}</p>
      <div class="redirect text-center">
        <p>
          Try again later,

          <router-link class="link" v-if="!isAuthenticated" to="/log-in">
            log-in
          </router-link>

          <router-link class="link" v-if="isAuthenticated" to="/your-account">
            your-account
          </router-link>

          or
          <router-link class="link" to="/">return home</router-link>.
        </p>
        <p>
          If the problem persists, please, contact our
          <a
            class="link"
            target="_blank"
            href="mailto:biglodevelop@gmail.com?subject=Password Reset Error"
            >support</a
          >
        </p>
      </div>
    </div>

    <!-- Form for Resetting Password -->
    <form v-if="!success" @submit.prevent="handlePasswordReset">
      <!-- New Password Field -->
      <div>
        <label for="new-password" class="block text-darkblue font-bold mb-1"
          >New Password</label
        >
        <div class="flex">
          <input
            :type="passwordFieldType"
            autocomplete="off"
            class="w-full p-3 border border-grey rounded-l-lg focus:outline-none focus:border-darkblue focus:shadow-md"
            name="new-password"
            id="new-password"
            v-model="form.newPassword"
            required
          />
          <button
            type="button"
            class="min-w-[50px] p-3 bg-darkblue text-yellow rounded-r-lg border border-l-0 border-darkblue hover:bg-yellow hover:text-darkblue"
            @click="togglePassword"
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
        <span
          v-if="!isPasswordComplex() && form.newPassword.length > 0"
          class="text-redError text-center block mt-2"
        >
          {{ passwordErrorMessage }}
        </span>
      </div>

      <!-- Confirm Password Field -->
      <div class="mt-4">
        <label for="confirm-password" class="block text-darkblue font-bold mb-1"
          >Confirm Password</label
        >
        <div class="flex">
          <input
            :type="passwordFieldType"
            autocomplete="off"
            class="w-full p-3 border border-grey rounded-l-lg focus:outline-none focus:border-darkblue focus:shadow-md"
            name="confirm-password"
            id="confirm-password"
            v-model="form.confirmPassword"
            required
          />
          <button
            type="button"
            class="min-w-[50px] p-3 bg-darkblue text-yellow rounded-r-lg border border-l-0 border-darkblue hover:bg-yellow hover:text-darkblue"
            @click="togglePassword"
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
        <span
          v-if="form.confirmPassword.length > 0 && !isPasswordMatch()"
          class="text-redError text-center block mt-2"
        >
          Passwords do not match
        </span>
      </div>

      <!-- Submit Button -->
      <button
        type="submit"
        class="w-full mt-6 py-3 bg-darkblue text-yellow font-semibold rounded-lg hover:bg-yellow hover:text-darkblue"
        :disabled="!isFormValid()"
      >
        Reset Password
      </button>

      <!-- Feedback -->
      <div v-if="serverMessage" :class="[messageClass, 'mt-4 text-center']">
        {{ serverMessage }}
      </div>
    </form>

    <!-- Notification -->
    <NotificationCustom
      v-if="notification.message"
      :type="notification.type"
      :message="notification.message"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import NotificationCustom from "@/components/common/NotificationCustom.vue";

export default {
  components: { NotificationCustom },
  data() {
    return {
      form: {
        newPassword: "",
        confirmPassword: "",
      },
      token: "", // Token extracted from the URL
      passwordErrorMessage: "",
      serverMessage: "",
      success: false,
      error: false,
      notification: {
        message: "",
        type: "",
      },
      countdown: 10,
      redirectInterval: null,
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
    };
  },
  computed: {
    // Computed property to determine the redirect path based on authentication status
    redirectPath() {
      return this.isAuthenticated ? "/your-account" : "/log-in";
    },
    messageClass() {
      return this.success ? "text-greenSuccess" : "text-redError";
    },
    ...mapGetters(["isAuthenticated"]),
  },
  mounted() {
    // Extract the token from the URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");
    if (!this.token) {
      this.serverMessage = "Invalid or missing token.";
      this.error = true;
    }
  },
  beforeUnmount() {
    clearInterval(this.redirectInterval);
  },
  methods: {
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
    isPasswordComplex() {
      const { newPassword } = this.form;
      if (!newPassword) {
        return true;
      }
      const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;

      if (newPassword.length < 10) {
        this.passwordErrorMessage =
          "Password must contain at least 10 characters";
        return false;
      } else if (!symbolRegex.test(newPassword)) {
        this.passwordErrorMessage =
          "Password must contain at least one special symbol";
        return false;
      } else if (!uppercaseRegex.test(newPassword)) {
        this.passwordErrorMessage =
          "Password must contain at least one uppercase letter";
        return false;
      } else if (!lowercaseRegex.test(newPassword)) {
        this.passwordErrorMessage =
          "Password must contain at least one lowercase letter";
        return false;
      } else if (!numberRegex.test(newPassword)) {
        this.passwordErrorMessage = "Password must contain at least one number";
        return false;
      } else {
        this.passwordErrorMessage = "";
        return true;
      }
    },
    isPasswordMatch() {
      return this.form.newPassword === this.form.confirmPassword;
    },
    isFormValid() {
      return this.isPasswordComplex() && this.isPasswordMatch();
    },
    async handlePasswordReset() {
      if (!this.token) {
        this.serverMessage = "Invalid or missing token.";
        this.error = true;
        this.showNotification("error", this.serverMessage);
        return;
      }

      if (!this.isFormValid()) {
        this.serverMessage = "Please correct the errors in the form.";
        this.error = true;
        this.showNotification("error", this.serverMessage);
        return;
      }

      try {
        const response = await axios.patch("/api/users/reset-password", {
          token: this.token,
          newPassword: this.form.newPassword,
        });
        this.serverMessage =
          response.data.message || "Password reset successfully!";
        this.success = true;
        this.error = false;
        this.showNotification("success", this.serverMessage);
        this.startRedirectCountdown();
      } catch (error) {
        this.serverMessage =
          error.response?.data?.error || "An error occurred.";
        this.error = true;
        this.success = false;
        this.showNotification("error", this.serverMessage);
      }
    },
    showNotification(type, message) {
      this.notification = { type, message };
    },
    startRedirectCountdown() {
      this.redirectInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.redirectInterval);
          this.$router.push(this.redirectPath);
        }
      }, 1000);
    },
  },
};
</script>
