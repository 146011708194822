<template>
  <div class="map-search">
    <div class="header-container w-[100svw] shadow">
      <CommonHeader />
    </div>
    <div class="main-container">
      <!-- Filters component -->
      <div class="filter-container">
        <SearchFilters />
      </div>

      <!-- Map component -->
      <div class="map-container">
        <MapTiler />
      </div>
      <div class="results-container">
        <AdvertisementsResults />
      </div>
    </div>
    <CommonFooter />
  </div>
</template>
<script>
import CommonHeader from "@/components/common/Header.vue";
import SearchFilters from "@/components/Search/Spaces/Filters.vue";
import MapTiler from "@/components/Search/Spaces/MapTiler.vue";
import AdvertisementsResults from "@/views/AdvertisementsResults";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MapResultsPage",
  metaInfo: {
    title: "Map Search | Biglo",
  },
  components: {
    CommonHeader,
    SearchFilters,
    MapTiler,
    AdvertisementsResults,
    CommonFooter,
  },
});
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: row;
}
.filter-container {
  width: 22svw;
  overflow-y: auto;
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.map-container {
  height: calc(100svh - 6rem);
  width: 39svw;
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.results-container {
  width: 39svw;
  overflow-y: scroll;
  padding: 0 0.5rem 0 0.5rem;
}
</style>
