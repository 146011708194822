<template>
  <div class="relative mx-auto">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-chart-pie-simple-circle-currency"></i>
      5th Step: Industry/Sector
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select the industries/sectors in which your company operates)
    </span>

    <!-- Switches for equipment options -->
    <SwitchesCheckboxes
      class="relative mx-auto"
      :options="sectorIndustryOptions"
      :model-value="sectorIndustry"
      @update:modelValue="updateSectorIndustryData"
    />
    <div class="button-container relative mx-auto w-fit">
      <button
        :disabled="!canProceed"
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-darkblue text-yellow font-medium border border-darkblue hover:bg-yellow hover:text-darkblue disabled:bg-lightgrey disabled:text-darkblue disabled:cursor-not-allowed disabled:hover:bg-lightgrey disabled:hover:text-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "SectorIndustryStep",
  components: { SwitchesCheckboxes },
  props: {
    sectorIndustry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canProceed() {
      // Check if at least one checkbox is selected
      return Object.values(this.sectorIndustry).some((value) => value === true);
    },
  },
  data() {
    return {
      sectorIndustryOptions: [
        {
          name: "retail",
          label: "Retail",
          value: false,
        },
        {
          name: "foodsAndDrinks",
          label: "Foods & Drinks",
          value: false,
        },
        {
          name: "pharma",
          label: "Pharmaceutical",
          value: false,
        },
        {
          name: "automotive",
          label: "Automotive",
          value: false,
        },
        {
          name: "technology",
          label: "Technology",
          value: false,
        },
        {
          name: "clothes",
          label: "Clothes",
          value: false,
        },
      ],
    };
  },
  methods: {
    updateSectorIndustryData(updatedValue) {
      this.$emit("update:sectorIndustry", updatedValue); // Sync data with parent
    },
    nextStep() {
      this.$emit("change-step", "ordersManagement"); // Emit step change
    },
  },
};
</script>

<style scoped></style>
