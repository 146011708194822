<template>
  <div class="relative mx-auto">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-file-contract"></i>
      9th Step: Contract
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select as many types of contract your company works with)
    </span>

    <!-- Switches for equipment options -->
    <SwitchesCheckboxes
      class="relative mx-auto"
      :options="contractOptions"
      :model-value="contract"
      @update:modelValue="updateContractData"
    />
    <div class="button-container relative mx-auto w-fit">
      <button
        :disabled="!canProceed"
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-darkblue text-yellow font-medium border border-darkblue hover:bg-yellow hover:text-darkblue disabled:bg-lightgrey disabled:text-darkblue disabled:cursor-not-allowed disabled:hover:bg-lightgrey disabled:hover:text-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "ContractStep",
  components: { SwitchesCheckboxes },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canProceed() {
      // Check if at least one checkbox is selected
      return Object.values(this.contract).some((value) => value === true);
    },
  },
  data() {
    return {
      contractOptions: [
        {
          name: "shortTermOperations",
          label: "Short-Term Operations",
          value: false,
        },
        {
          name: "longTermOperations",
          label: "Long-Term Operations",
          value: false,
        },
        {
          name: "occasionalOperations",
          label: "Occasional Operations",
          value: false,
        },
      ],
    };
  },
  methods: {
    updateContractData(updatedValue) {
      this.$emit("update:contract", updatedValue); // Sync data with parent
    },
    nextStep() {
      this.$emit("change-step", "otherLogisticsDetails"); // Emit step change
    },
  },
};
</script>

<style scoped></style>
