<template>
  <div class="relative mx-auto">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-truck"></i>
      8th Step: Licenses
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select which licenses and certificates your company has)
    </span>

    <!-- Switches for equipment options -->
    <SwitchesCheckboxes
      class="relative mx-auto"
      :options="licensesOptions"
      :model-value="licenses"
      @update:modelValue="updateLicensesData"
    />
    <div class="button-container relative mx-auto w-fit">
      <button
        :disabled="!canProceed"
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-darkblue text-yellow font-medium border border-darkblue hover:bg-yellow hover:text-darkblue disabled:bg-lightgrey disabled:text-darkblue disabled:cursor-not-allowed disabled:hover:bg-lightgrey disabled:hover:text-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "LicensesStep",
  components: { SwitchesCheckboxes },
  props: {
    licenses: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canProceed() {
      // Check if at least one checkbox is selected
      return Object.values(this.licenses).some((value) => value === true);
    },
  },
  data() {
    return {
      licensesOptions: [
        { name: "iso9001", label: "ISO 9001", value: false },
        { name: "iso14001", label: "ISO 14001", value: false },
        { name: "iso45001", label: "ISO 45001", value: false },
        { name: "ohsas18001", label: "OSHAS 18001", value: false },
        { name: "haccp", label: "HACCP", value: false },
        { name: "customs", label: "Customs Certificate", value: false },
        { name: "chemicalHandling", label: "Chemicals Handling", value: false },
        { name: "fireSafety", label: "Fire Safety", value: false },
        { name: "breeam", label: "BREEAM Certificate", value: false },
        { name: "leed", label: "LEED Certificate", value: false },
      ],
    };
  },
  methods: {
    updateLicensesData(updatedValue) {
      this.$emit("update:licenses", updatedValue); // Sync data with parent
    },
    nextStep() {
      this.$emit("change-step", "contract"); // Emit step change
    },
  },
};
</script>

<style scoped></style>
