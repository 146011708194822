<template>
  <div class="form-container">
    <h1 for="login-form" class="title">
      <i class="fa-solid fa-sign-in-alt fa-lg"></i>
      User Login
    </h1>
    <form id="login-form" @submit.prevent="handleSubmit">
      <label for="email" class="font-semibold mb-2 block text-white"
        >E-mail</label
      >
      <input
        class="px-4 py-3"
        type="email"
        id="email"
        v-model="form.email"
        required
      />

      <div class="password-div mt-4">
        <label for="password" class="font-semibold mb-2 block text-white"
          >Password</label
        >
        <div class="input-and-button">
          <input
            :type="passwordFieldType"
            autocomplete="off"
            class="password px-4 py-3"
            name="password"
            id="password"
            v-model="form.password"
            required
          />
          <button
            type="button"
            class="toggle-password"
            @click="togglePassword()"
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
      </div>

      <router-link
        to="/forgotten-password"
        target="_blank"
        class="block font-semibold my-6 text-yellow hover:underline"
        >Forgot your password?</router-link
      >

      <button
        type="submit"
        class="submit-button block mx-auto rounded-lg px-4 py-3 bg-yellow text-darkblue font-semibold border border-yellow hover:bg-darkblue hover:text-yellow"
      >
        Log-in
      </button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserLogin",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async handleSubmit() {
      try {
        this.errorMessage = null;

        // Call the login action from Vuex
        await this.login({
          email: this.form.email,
          password: this.form.password,
        });

        // Redirect to the user's account page
        this.$router.push({ name: "your-account" });
      } catch (error) {
        this.errorMessage = error.message || "An error occurred during login.";
      }
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
  },
};
</script>

<style scoped>
.form-container {
  background-color: #061656;
  padding: 20px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px auto 26px auto;
  text-align: center;
  color: white;
}

input[type="email"],
input[type="password"] {
  border-radius: 8px;
  width: 100% !important;
  margin: auto;
  border: 1px solid white;
  box-sizing: border-box;
  background-color: #051033;
  color: white;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #efb615;
  outline: none;
}

.password-div {
  display: flex;
  flex-direction: column;
}

.input-and-button {
  display: flex;
  flex-direction: row;
}

.toggle-password {
  min-width: 50px;
  padding: 0.67rem;
  background-color: #efb615;
  border-radius: 0 8px 8px 0;
  color: #061656;
  border: 1px solid #efb615;
  flex: 1;
  margin: 0;
}

.toggle-password:hover {
  background-color: #061656;
  color: #efb615;
}

.password {
  border-right: none !important;
  border-radius: 8px 0 0 8px !important;
  width: calc(100% - 50px);
  margin: auto;
  border: 1px solid #ccc;
}

.error-message {
  margin-top: 10px;
  color: red;
  text-align: center;
}
</style>
