<template>
  <div
    class="flex flex-col min-h-screen bg-lightestGrey font-montserrat relative block"
  >
    <!-- Main Content -->
    <main class="relative flex flex-col min-lg:flex-row flex-grow gap-4 p-4">
      <!-- Filters Toggle Button for Small Screens -->
      <button
        @click="toggleFilters"
        class="bg-darkblue text-yellow font-semibold py-2 px-4 rounded shadow-lg min-lg:hidden"
      >
        Filters
      </button>

      <!-- Filters Section -->
      <aside
        :class="[
          'bg-white p-6 shadow-lg rounded-lg border border-lightgrey flex-shrink-0 transition-all duration-300',
          isFiltersVisible
            ? 'mx-auto z-10 w-full h-full md:max-w-md'
            : 'hidden',
          'min-lg:block min-lg:relative min-lg:top-auto min-lg:left-auto min-lg:z-auto min-lg:w-1/4',
        ]"
      >
        <ServicesSearchFilters @apply-filters="handleFiltersApplied" />
      </aside>

      <!-- Results Section -->
      <section
        class="flex-grow bg-white p-6 shadow-lg rounded-lg border border-lightgrey"
      >
        <ServicesSearchResults />
      </section>
    </main>
  </div>
</template>

<script>
import ServicesSearchFilters from "@/components/Search/Services/Filters.vue";
import ServicesSearchResults from "@/components/Search/Services/Results.vue";

export default {
  name: "ServicesSearch",
  components: {
    ServicesSearchFilters,
    ServicesSearchResults,
  },
  data() {
    return {
      isFiltersVisible: false, // State for filters visibility
    };
  },
  methods: {
    toggleFilters() {
      this.isFiltersVisible = !this.isFiltersVisible;
    },
    handleFiltersApplied(filters) {
      console.log("Filters Applied:", filters);
    },
  },
};
</script>

<style scoped>
/* Optional: Add backdrop styling for small screens */
aside.absolute {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
}
</style>
