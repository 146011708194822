<template>
  <div class="w-full text-white py-4 px-8 rounded-lg bg-darkblue">
    <!-- Greeting Section -->
    <h2 class="text-xl font-semibold mb-3 text-center">
      Welcome back, <span class="font-bold">{{ username }}</span
      >!
    </h2>
    <div class="bg-white h-0.5 w-full mb-4"></div>

    <!-- Navigation Buttons -->
    <ul>
      <li v-for="button in buttons" :key="button.section">
        <div
          @click="handleButtonClick(button)"
          :class="[
            'flex items-center mt-1 py-3 px-4 rounded-lg cursor-pointer transition-colors',
            currentSection === button.section
              ? 'bg-yellow text-darkblue'
              : 'text-white hover:text-yellow hover:bg-darkblue hover:bg-opacity-10',
            button.requiresCorporate && !corporateId
              ? 'cursor-not-allowed opacity-50'
              : '',
          ]"
          :data-text="
            button.requiresCorporate && !corporateId
              ? 'You need to register a corporate first'
              : ''
          "
        >
          <i :class="button.icon + ' mr-2'"></i>
          {{ button.text }}
        </div>
        <div
          v-if="button.separator"
          class="border-t border-gray-300 my-2"
        ></div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    currentSection: String,
  },
  data() {
    return {
      buttons: [
        {
          section: "UserDetails",
          text: "User Details",
          icon: "fa-solid fa-user",
          separator: false,
        },
        {
          section: "MessageCenter",
          text: "Message Center",
          icon: "fa-solid fa-mailbox-flag-up",
          separator: true,
        },
        {
          section: "MyFavourites",
          text: "My Favourites",
          icon: "fa-solid fa-heart",
          separator: false,
        },
        {
          section: "SavedSearches",
          text: "Saved Searches",
          icon: "fa-solid fa-user-magnifying-glass",
          separator: true,
        },
        {
          section: "SpacesManagement",
          text: "Spaces Management",
          icon: "fa-solid fa-shop-lock",
          separator: false,
        },
        {
          section: "ServicesManagement",
          text: "Services Management",
          icon: "fa-solid fa-gears",
          separator: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    username() {
      return this.user ? this.user.name : "Guest";
    },
  },
  methods: {
    handleButtonClick(button) {
      if (!button.requiresCorporate || this.corporateId) {
        this.$emit("section-change", button.section);
      }
    },
  },
};
</script>

<style scoped></style>
