<template>
  <CommonHeader />
  <VerificateUser />
</template>
<script>
import CommonHeader from "@/components/common/Header.vue";
import VerificateUser from "@/components/SignIn/VerificateUser.vue";

export default {
  name: "UserVerificationView",
  components: {
    CommonHeader,
    VerificateUser,
  },
};
</script>
<style></style>
