<template>
  <div class="map-wrap">
    <div class="map" ref="mapContainer"></div>
  </div>
</template>

<script setup>
import { Map, MapStyle, Marker, config } from "@maptiler/sdk";
import { shallowRef, onMounted, onUnmounted, markRaw } from "vue";
import "@maptiler/sdk/dist/maptiler-sdk.css";

const mapContainer = shallowRef(null);
const map = shallowRef(null);

onMounted(() => {
  config.apiKey = process.env.VUE_APP_MAPTILER_API_KEY;

  const initialState = { lng: -3.1, lat: 40.6, zoom: 4.9 };

  map.value = markRaw(
    new Map({
      container: mapContainer.value,
      style: MapStyle.STREETS,
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
      minZoom: 3.5,
    }),
  );

  new Marker({ color: "#004AAD" })
    .setLngLat([-0.37739, 39.46975])
    .addTo(map.value);
  new Marker({ color: "#004AAD" }).setLngLat([-0.475, 38.7]).addTo(map.value);
  new Marker({ color: "#004AAD" })
    .setLngLat([-1.13004, 37.98704])
    .addTo(map.value);
  new Marker({ color: "#004AAD" })
    .setLngLat([-3.70379, 40.416775])
    .addTo(map.value);
  new Marker({ color: "#004AAD" })
    .setLngLat([2.154007, 41.390205])
    .addTo(map.value);
  new Marker({ color: "#004AAD" })
    .setLngLat([-2.935013, 43.262985])
    .addTo(map.value);
  new Marker({ color: "#004AAD" })
    .setLngLat([-4.72372, 41.65518])
    .addTo(map.value);
  new Marker({ color: "#004AAD" })
    .setLngLat([-8.712447, 42.231358])
    .addTo(map.value);
  new Marker({ color: "#004AAD" })
    .setLngLat([-5.994072, 37.392529])
    .addTo(map.value);
}),
  onUnmounted(() => {
    map.value?.remove();
  });
</script>

<style scoped>
.map-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
</style>
