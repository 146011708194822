<template>
  <div class="forgotten-password-view min-h-dvh">
    <CommonHeader />
    <PasswordForgotten />
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import PasswordForgotten from "@/components/SignIn/PasswordForgotten.vue";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ForgottenPassword",
  metaInfo: {
    title: "Forgotten Password | Biglo",
  },
  components: {
    CommonHeader,
    PasswordForgotten,
    CommonFooter,
  },
});
</script>

<style scoped></style>
