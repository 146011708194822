<template>
  <div class="step-container storage-step">
    <span class="step-form-title">
      <i class="fa-solid fa-warehouse-full"></i>
      6th step: Storage Methods
    </span>
    <span class="step-form-subtitle">
      (Select as many options as needed or skip to the next method if none
      apply)
    </span>

    <!-- Navigation Buttons -->
    <div class="storage-input-nav-buttons inputs-nav-buttons">
      <button
        class="input-nav-button"
        data-text="Block Storage"
        @click="$emit('change-input', 'storage', 'blockStorage')"
        :class="{
          'current-button': currentInput === 'blockStorage',
        }"
      >
        <i
          class="fa-solid fa-boxes-stacked"
          :class="{
            'fa-xl': currentInput === 'blockStorage',
            'fa-lg': currentInput !== 'blockStorage',
          }"
        ></i>
      </button>
      <div class="input-separator sep-height separator-blue"></div>
      <button
        class="input-nav-button"
        data-text="Direct Storage"
        @click="$emit('change-input', 'storage', 'directStorage')"
        :class="{
          'current-button': currentInput === 'directStorage',
        }"
      >
        <i
          class="fa-solid fa-boxes-packing"
          :class="{
            'fa-xl': currentInput === 'directStorage',
            'fa-lg': currentInput !== 'directStorage',
          }"
        ></i>
      </button>
      <div class="input-separator sep-height separator-blue"></div>
      <button
        class="input-nav-button"
        data-text="Automated Storage"
        @click="$emit('change-input', 'storage', 'automatedStorage')"
        :class="{
          'current-button': currentInput === 'automatedStorage',
        }"
      >
        <i
          class="fa-solid fa-robot"
          :class="{
            'fa-xl': currentInput === 'automatedStorage',
            'fa-lg': currentInput !== 'automatedStorage',
          }"
        ></i>
      </button>
    </div>

    <!-- Dynamic Input Groups -->
    <div
      v-if="currentInput === 'blockStorage'"
      class="input-group blockStorage-input-group"
    >
      <label for="blockStorage-input-group">
        <i class="fa-solid fa-boxes-stacked"></i> Block Storage
      </label>
      <SwitchesCheckboxes
        :options="blockStorageOptions"
        :model-value="storageData.blockStorage"
        @update:modelValue="updateBlockStorage"
      />
      <button
        @click="$emit('change-input', 'storage', 'directStorage')"
        class="next-step-button"
      >
        Next method
      </button>
    </div>

    <div
      v-if="currentInput === 'directStorage'"
      class="input-group directStorage-input-group"
    >
      <label for="directStorage-input-group">
        <i class="fa-solid fa-boxes-packing"></i> Direct Storage
      </label>
      <SwitchesCheckboxes
        :options="directStorageOptions"
        :model-value="storageData.directStorage"
        @update:modelValue="updateDirectStorage"
      />
      <button
        @click="$emit('change-input', 'storage', 'automatedStorage')"
        class="next-step-button"
      >
        Next method
      </button>
    </div>

    <div
      v-if="currentInput === 'automatedStorage'"
      class="input-group automatedStorage-input-group"
    >
      <label for="automatedStorage-input-group">
        <i class="fa-solid fa-robot"></i> Automated Storage
      </label>
      <SwitchesCheckboxes
        :options="automatedStorageOptions"
        :model-value="storageData.automatedStorage"
        @update:modelValue="updateAutomatedStorage"
      />
      <button
        :disabled="!arePreviousStepsFilled('goods')"
        @click="$emit('change-step', 'goods')"
        class="next-step-button"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "StorageStep",
  components: { SwitchesCheckboxes },
  props: {
    storageData: {
      type: Object,
      required: true,
    },
    currentInput: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      blockStorageOptions: [
        { name: "rackPushBack", label: "Rack Push Back", value: false },
        { name: "dynamicRack", label: "Dynamic Rack", value: false },
      ],
      directStorageOptions: [
        { name: "conventionalRack", label: "Conventional Rack", value: false },
        { name: "cantileverRack", label: "Cantilever Rack", value: false },
        { name: "mobileBaseRack", label: "Mobile Base Rack", value: false },
        { name: "driveInRack", label: "Drive In Rack", value: false },
        {
          name: "palletShuttleRack",
          label: "Pallet Shuttle Rack",
          value: false,
        },
      ],
      automatedStorageOptions: [
        { name: "verticalCarousel", label: "Vertical Carousel", value: false },
        {
          name: "horizontalCarousel",
          label: "Horizontal Carousel",
          value: false,
        },
        { name: "stackerCrane", label: "Stacker Crane", value: false },
        { name: "verticalShuttle", label: "Vertical Shuttle", value: false },
      ],
    };
  },
  methods: {
    updateBlockStorage(updatedData) {
      this.$emit("update:storage-data", {
        ...this.storageData,
        blockStorage: updatedData,
      });
    },
    updateDirectStorage(updatedData) {
      this.$emit("update:storage-data", {
        ...this.storageData,
        directStorage: updatedData,
      });
    },
    updateAutomatedStorage(updatedData) {
      this.$emit("update:storage-data", {
        ...this.storageData,
        automatedStorage: updatedData,
      });
    },
  },
};
</script>
