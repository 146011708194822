<template>
  <iframe
    src="/footer/index.html"
    frameborder="0"
    style="width: 100%; height: 311px"
    id="webflow-container"
  ></iframe>
</template>

<script>
export default {
  name: "LandingPage",
  mounted() {
    this.attachIframeNavigationHandler();
  },
  methods: {
    attachIframeNavigationHandler() {
      const iframe = document.getElementById("webflow-container");

      iframe.onload = () => {
        try {
          // Listen for navigation clicks within the iframe
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          iframeDocument.addEventListener("click", (event) => {
            const link = event.target.closest("a"); // Detect the nearest <a> tag clicked
            if (link && link.href) {
              const targetURL = new URL(link.href);

              // Check if the link points to an external page (not within /webflow/)
              if (!targetURL.pathname.startsWith("/webflow/")) {
                event.preventDefault(); // Prevent iframe navigation
                window.top.location.href = link.href; // Redirect the parent window
              }
            }
          });
        } catch (error) {
          console.error("Error attaching navigation handler:", error);
        }
      };
    },
  },
};
</script>

<style scoped>
#webflow-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
