<template>
  <transition name="fade">
    <div v-if="visible" :class="['notification', type]">
      <i :class="['fa', iconClass]"></i>
      <span class="message">{{ message }}</span>
      <button @click="hide" :class="type">
        <i class="fa fa-times"></i>
      </button>
      <div
        class="progress-bar"
        :style="{ width: progressBarWidth + '%' }"
      ></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NotificationCustom",
  props: {
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 10000,
    },
  },
  data() {
    return {
      visible: true,
      progressBarWidth: 100,
      remainingTime: this.duration,
      timer: null,
      startTime: null,
    };
  },
  computed: {
    iconClass() {
      switch (this.type) {
        case "success":
          return "fa-circle-check";
        case "info":
          return "fa-circle-info";
        case "warning":
          return "fa-circle-exclamation";
        case "error":
          return "fa-triangle-exclamation";
        default:
          return "fa-circle-info";
      }
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.startTime = Date.now();
      this.timer = setInterval(this.updateTime, 100);
    },
    updateTime() {
      const now = Date.now();
      const elapsed = now - this.startTime;
      this.remainingTime -= elapsed;
      this.startTime = now;

      if (this.remainingTime <= 0) {
        this.remainingTime = 0;
        this.hide();
      }

      this.updateProgressBar();
    },
    updateProgressBar() {
      this.progressBarWidth = (this.remainingTime / this.duration) * 100;
    },
    hide() {
      this.visible = false;
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 16px;
  padding-right: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  gap: 20px; /* Add gap between elements */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 16px;
  opacity: 0.9;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.notification .fa {
  font-size: 18px;
}

.notification .message {
  text-align: center;
  flex-grow: 1; /* Take up the remaining space */
}

.notification button {
  background: none;
  border: none;
  color: inherit;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.notification button {
  color: #ffffff8b;
}
.notification button:hover {
  color: #ffffff;
}
.notification.warning button {
  color: #0000008b;
}
.notification.warning button:hover {
  color: black;
}
.notification.success button:hover {
  background-color: #00d112;
}

.notification.info button:hover {
  background-color: #0080ff;
}

.notification.warning button:hover {
  background-color: #f7b500;
}

.notification.error button:hover {
  background-color: #e60000;
}

.notification .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  transition: width 0.1s linear;
}

.notification.success {
  background-color: #00d112;
}

.notification.info {
  background-color: #0080ff;
}

.notification.warning {
  background-color: #f7b500;
  color: #212529;
}

.notification.error {
  background-color: #e60000;
}

/* Add fade transition for better UX */
.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}
</style>
