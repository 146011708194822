import axios from "axios";
import { createStore } from "vuex";
import apiClient from "@/api";

/**
 * Helper function to retrieve the stored user from localStorage.
 * @returns {Object|null} The parsed user object or null if not found or invalid.
 */
function getStoredUser() {
  try {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Error parsing stored user:", error);
    localStorage.removeItem("user"); // Clear invalid data
    return null;
  }
}

// Create the Vuex store
const store = createStore({
  // State holds the application's global data
  state: {
    isAuthenticated: false, // Tracks if the user is authenticated
    user: null, // Stores the logged-in user's data
    token: null, // Stores the authentication token
    userLogoBlob: null, // Stores the user's logo as a Blob
  },

  // Mutations are synchronous functions that modify the state
  mutations: {
    /**
     * Sets the user and token in the state and localStorage.
     * @param {Object} state - The Vuex state.
     * @param {Object} payload - Contains user and token.
     */
    SET_USER(state, { user, token }) {
      state.user = user;
      state.isAuthenticated = !!user;
      state.token = token;

      // Persist user and token in localStorage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);

      // Set the Authorization header for axios and apiClient
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },

    /**
     * Clears user data from the state and localStorage.
     * @param {Object} state - The Vuex state.
     */
    LOGOUT(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
      state.userLogoBlob = null;

      // Remove user and token from localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      // Remove the Authorization header from axios and apiClient
      delete axios.defaults.headers.common["Authorization"];
      delete apiClient.defaults.headers.common["Authorization"];
    },

    /**
     * Sets the user's logo Blob in the state.
     * @param {Object} state - The Vuex state.
     * @param {Blob|null} blob - The logo Blob or null if no logo.
     */
    SET_USER_LOGO_BLOB(state, blob) {
      state.userLogoBlob = blob;
    },
  },

  // Actions handle asynchronous operations and commit mutations
  actions: {
    /**
     * Initializes the store by checking localStorage for existing user data.
     * @param {Object} context - Vuex context (contains commit, state, etc.).
     */
    initializeStore({ commit }) {
      const storedUser = getStoredUser();
      const storedToken = localStorage.getItem("token") || null;

      // If user and token exist in localStorage, set them in the state
      if (storedUser && storedToken) {
        commit("SET_USER", { user: storedUser, token: storedToken });
      }
    },

    /**
     * Fetches the user's logo Blob from the server and commits it to the state.
     * @param {Object} context - Vuex context.
     * @param {string|null} logoPath - The path to the user's logo or null if no logo.
     */
    async fetchUserLogo({ commit }, logoPath) {
      if (logoPath) {
        const logoBlob = await fetch(`/api/images/${logoPath}`).then((res) =>
          res.blob(),
        );
        commit("SET_USER_LOGO_BLOB", logoBlob);
      } else {
        commit("SET_USER_LOGO_BLOB", null); // Clear the logo if no path is provided
      }
    },

    /**
     * Handles user login.
     * @param {Object} context - Vuex context.
     * @param {Object} credentials - Contains email and password.
     */
    async login({ commit, dispatch }, { email, password }) {
      try {
        const response = await axios.post("/api/users/login", {
          email,
          password,
        });

        // Commit user and token to the state
        commit("SET_USER", {
          user: response.data.user,
          token: response.data.token,
        });

        // Fetch and set the user's logo if available
        await dispatch("fetchUserLogo", response.data.user.logo);
      } catch (error) {
        if (error.response?.status === 401) {
          throw new Error("Invalid email or password.");
        } else {
          console.error("Error logging in:", error);
          throw new Error("An error occurred during login.");
        }
      }
    },

    /**
     * Handles user registration.
     * @param {Object} context - Vuex context.
     * @param {Object} payload - Registration data (e.g., name, email, password).
     */
    async register({ commit, dispatch }, payload) {
      try {
        const response = await axios.post("/api/users/register", payload);

        // Commit user and token to the state
        commit("SET_USER", {
          user: response.data.user,
          token: response.data.token,
        });

        // Fetch and set the user's logo if available
        await dispatch("fetchUserLogo", response.data.user.logo);
      } catch (error) {
        if (error.response?.status === 409) {
          throw new Error("This email address is already in use.");
        } else {
          console.error("Error registering user:", error);
          throw new Error("An error occurred during registration.");
        }
      }
    },

    /**
     * Handles user logout.
     * @param {Object} context - Vuex context.
     */
    logout({ commit }) {
      commit("LOGOUT");
    },

    /**
     * Fetches user data from the server and updates the state.
     * @param {Object} context - Vuex context.
     * @param {string} userId - The ID of the user to fetch.
     */
    async fetchUser({ commit, state, dispatch }, userId) {
      try {
        const response = await axios.get(`/api/users/${userId}`);

        if (response?.data) {
          // Commit user data to the state
          commit("SET_USER", { user: response.data, token: state.token });

          // Fetch and set the user's logo if available
          await dispatch("fetchUserLogo", response.data.logo);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
      }
    },
  },

  // Getters provide computed properties based on the state
  getters: {
    isAuthenticated: (state) => state.isAuthenticated, // Checks if the user is authenticated
    userName: (state) => state.user?.name || "Guest", // Returns the user's name or "Guest"
    user: (state) => state.user, // Returns the user object
    isVerified: (state) => state.user?.isVerified || false, // Checks if the user is verified
    userLogoBlob: (state) => state.userLogoBlob, // Returns the user's logo Blob
  },
});

export default store;
