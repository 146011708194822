<template>
  <div class="min-md:max-w-md md:max-w-full mx-auto">
    <div
      v-for="(otherLocation, index) in otherLocations"
      :key="index"
      class="other-location-item mb-4 border-b pb-4"
    >
      <label class="block text-xl text-darkblue font-bold mb-1">
        Location {{ index + 1 }}
      </label>

      <CountryInput
        class="mb-4"
        v-model="otherLocation.country"
        @country-selected="updateCountry(index, $event)"
      />
      <input
        type="text"
        v-model="otherLocation.city"
        required
        minlength="1"
        placeholder="City"
        class="w-full px-4 py-3 border border-1 rounded-lg"
      />
      <button
        type="button"
        @click="removeLocation(index)"
        class="mt-2 text-red-500"
        v-if="otherLocations.length > 1"
      >
        Remove Location
      </button>
    </div>
    <button
      type="button"
      @click="addLocation"
      class="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
      v-if="otherLocations.length < 5"
    >
      Add Another Location
    </button>
  </div>
</template>

<script>
import CountryInput from "@/components/common/Forms/CountryInput.vue";

export default {
  name: "OtherLocationsForm",
  components: {
    CountryInput,
  },
  data() {
    return {
      otherLocations: [{ country: "", city: "" }],
    };
  },
  methods: {
    addLocation() {
      if (this.otherLocations.length < 5) {
        this.otherLocations.push({ country: "", city: "" });
      }
    },
    removeLocation(index) {
      if (this.otherLocations.length > 1) {
        this.otherLocations.splice(index, 1);
      }
    },
    updateCountry(index, country) {
      this.otherLocations[index].country = country;
    },
    proceedToNextInput() {
      this.$emit("update:location", this.otherLocations);
      this.$emit("change-step", "hasInternationalCoverage");
    },
  },
};
</script>

<style scoped>
input {
  border: 1px solid;
  border-color: #696969;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow:
    0 4px 6px -1px rgb(0 128 255 / 0.25),
    0 2px 4px -2px rgb(0 128 255 / 0.25) !important;
}
input:focus:invalid {
  border-color: #f7b500;
  box-shadow:
    0 4px 6px -1px rgb(247 181 0 / 0.25),
    0 2px 4px -2px rgb(247 181 0 / 0.25);
}
input:not(:focus):user-invalid {
  border-color: #e60000;
}
input:user-valid {
  border-color: #00d112 !important;
}
input:focus:user-valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
input:valid {
  border-color: #00d112 !important;
}
input:focus:valid {
  border-color: #00d112 !important;
  box-shadow:
    0 4px 6px -1px rgb(0 209 18 / 0.25),
    0 2px 4px -2px rgb(0 209 18 / 0.25) !important;
}
.remove-location {
  cursor: pointer;
}
.add-location,
.next-step {
  transition:
    background-color 0.3s,
    color 0.3s;
}
</style>
